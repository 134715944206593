import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import { Container, Col, Row, Card } from "react-bootstrap";
import "../styles/Home.css";
import logistics from "../assets/logistics.jpg";
import events from "../assets/events.jpg";
import investments from "../assets/investments2.png";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [expandedText, setExpandedText] = useState({
    media: false,
    community: false,
  });

  const navigate = useNavigate();

  const toggleText = (section) => {
    setExpandedText((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleCardClick = (path) => {
    navigate(path); // Navigate to the specified path when a card is clicked
  };

  return (
    <>
      <Header />
      <div>
        <Carousel>
          <Carousel.Item>
            <img className="d-block w-100" src={logistics} alt="First slide" />
            <Carousel.Caption>
              <h3>Logistics</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={investments}
              alt="Second slide"
            />
            <Carousel.Caption>
              <h3>Investments</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={events} alt="Third slide" />
            <Carousel.Caption>
              <h3>Events</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

        <Container>
          <div className="centered-text">
            <h2>Koduor And Company Holding Limited</h2>
            <p>
              Koduor And Company Holding Limited is a private owned investment
              company with headquarters in Nairobi. Koduor is an investment
              channel providing investors access to a portfolio of inaccessible,
              quality, diversified investment. Koduor’s mission is to make
              investments in real and tangible business that seek to create a
              platform for nurturing and growing young skills through which
              needy and poor people access mentorship and support to create and
              build extraordinary enterprises. The Koduor and Company Limited’s
              investment are organized into four key verticals
            </p>
          </div>
          <Container className="mt-5">
            <h2 className="text-center mb-4">Our Businesses</h2>
            <Row>
              <Col md={4}>
                <Card
                  className="mb-4 clickable-card"
                  onClick={() => handleCardClick("/brand-dynamics-event")}
                >
                  <Card.Img variant="top" src={logistics} />
                  <Card.Body className="bg-primary text-white">
                    <Card.Title>01</Card.Title>
                    <Card.Text>Brand Centric Dynamics</Card.Text>
                    <Card.Link href="#" className="text-white">
                      Read More
                    </Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card
                  className="mb-4 clickable-card"
                  onClick={() => handleCardClick("/nu-media")}
                >
                  <Card.Img variant="top" src={events} />
                  <Card.Body className="bg-primary text-white">
                    <Card.Title>02</Card.Title>
                    <Card.Text>NU Media</Card.Text>
                    <Card.Link href="#" className="text-white">
                      Read More
                    </Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card
                  className="mb-4 clickable-card"
                  onClick={() => handleCardClick("/fika-innovation")}
                >
                  <Card.Img variant="top" src={investments} />
                  <Card.Body className="bg-primary text-white">
                    <Card.Title>03</Card.Title>
                    <Card.Text>Fika Innovations</Card.Text>
                    <Card.Link href="#" className="text-white">
                      Read More
                    </Card.Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          <br /> <br />
          <div className="text-columns">
            <div className="column">
              <div className="yellow-rectangle"></div>
              <div className="text-content">
                <h2>Media and Entertainment</h2>
                <p>
                  • <b>Brand Centric Dynamics</b> – An advertising and marketing
                  agency. Its mandate is to offer to public a consumer centric;
                  new and cutting age marketing strategy. The four key services
                  offered are brand strategy, business development strategy,
                  adverting and marketing, communication.
                </p>

                {expandedText.media && (
                  <div>
                    <p>
                      • <b>Brand Dynamics</b> – An events production and
                      management company. Its mandate is to provide events
                      logistical services for a successful event. The key
                      services offered are hire of public address systems,
                      daylight screens, events panning, tents, chairs, and
                      décor.{" "}
                    </p>
                    <p>
                      • <b>Nu Media Services</b> – A media production company
                      with mandate to keep the public abreast with the
                      underground current affairs. We achieve this through our
                      by-monthly magazine Dove Nairobi which was being
                      distributed to more than 200 worship places until 2022
                      before we took a long hiatus to strategize. We embark on
                      our news reporting with a digital presence across all
                      social media platforms and google search, and an online
                      Television in You tube. We also offer media related
                      services to public in general media production thus video
                      and photography, commercial content production for tv,
                      radio, and social media.
                    </p>
                  </div>
                )}
                <a href="#" onClick={() => toggleText("media")}>
                  {expandedText.media ? "Read Less" : "Read More"}
                </a>
              </div>
              <hr />
            </div>

            <div className="column">
              <div className="text-content">
                <h2>Community Social Outreach</h2>
                <p>
                  • <b>Koduor Scholars</b> – seeks to create access to quality
                  and universal higher education to families from poor family
                  backgrounds and underserved communities like slums and rural
                  areas with priority being primary and secondary education. We
                  have eye to opening our own educational centers in the very
                  near future.{" "}
                </p>
                {expandedText.community && (
                  <div>
                    <p>
                      • <b>Koduor Leaders</b> - Seeks to offer transformative
                      empowerment through holistic coaching and mentorship. Our
                      priority being the most wanted youths who are engaging in
                      crime and activities that are causing unrest, and
                      prostitutes. Our programs aims to rehabilitate and then
                      equip beneficiaries with professional skills to start over
                      a fresh life. Our objective is to create leaders to
                      continue our mission to the rest of the country.{" "}
                    </p>
                    <p>
                      • <b>Koduor Health</b> – Seeking to create measures and
                      policies that makes it possible for everyone to have
                      access to quality and universal health care services.
                    </p>
                  </div>
                )}
                <a href="#" onClick={() => toggleText("community")}>
                  {expandedText.community ? "Read Less" : "Read More"}
                </a>
              </div>
              <hr />
            </div>
          </div>
          <div className="text-columns">
            <div className="column">
              <div className="yellow-rectangle"></div>
              <div className="text-content">
                <h2>Transport and Logistics</h2>
                <p>
                  • <b>Fika Safaris</b> – offering a public and adventure
                  transport services.{" "}
                </p>
                <p>
                  • <b>Fika Freight and Forwarding</b> – A parcel delivery
                  company. We offer within an hour delivery, same day delivery,
                  and next day delivery. We pride of over 100 riders and
                  vehicles within Nairobi.
                </p>
              </div>
              <hr />
            </div>

            <div className="column">
              <div className="text-content">
                <h2>Portable Kitchens</h2>
                <p>
                  This division is involved in outside catering and events
                  management. The Portable Kitchens Services include
                  <ul>
                    <li>Customized menu to preference audience.</li>
                    <li>Customized bar</li>
                    <li>Tents and décor</li>
                  </ul>
                </p>
              </div>
              <hr />
            </div>
          </div>
        </Container>
      </div>

      <Footer />
    </>
  );
};

export default Home;
