import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import ComingSoon from "../ComingSoon";

const PortableKitchen = () => {
  return (
    <>
      <Header />
      <ComingSoon />
      <Footer />
    </>
  );
};

export default PortableKitchen;
